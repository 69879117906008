import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css")

import { Latvian } from "flatpickr/dist/l10n/lv.js"
flatpickr.localize(Latvian); // default locale is now Russian

$(() => {
  $('[title]').tooltip()
  $('[rel="popover"]').popover()

  $(".advanced-search-button").click(function(e) {
    $(".advanced-list-filter").toggle();
    return false;
  });

  flatpickr("input.date", {
    dateFormat: "d.m.Y",
    allowInput: true,
  });
})
