/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from "jquery";
import "../stylesheets/main.scss"

window.$ = jQuery
window.jQuery = jQuery

// RRU-2021:
// If using import, loading bootstrap gets hoisted to a place
// where window.$ is undefined, resulting in error.
// Use require as a fix.
// RRU-2021:
// bootstrap skripti nav proper js moduļi, jo ja būtu, ieimportētu jQ paši
// nevis vilktu ārā no window
require('bootstrap-sass/js/bootstrap-tooltip')
require('bootstrap-sass/js/bootstrap-collapse')
require('bootstrap-sass/js/bootstrap-popover')
require('bootstrap-sass/js/bootstrap-tab')
require('bootstrap-sass/js/bootstrap-dropdown')

require("rails-ujs").start();

require('../js/init.js');

//
console.log('Hello World from Kopiena');
